<template>
  <div>
    <div class="banner">
      <div class="banner-content">
        <h2>客宝宝</h2>
        <p> 专注于企业售后服务的业务模式创新 <br>
          和精益化管理</p>
      </div>
    </div>
    <div class="brief">
      <div class="brief-title">
        公司简介
      </div>
      <div class="brief-content">
        <div class="brief-img">
          <img src="../../../public/kbb.png" alt="">
        </div>
        <div class="brief-content">
          <p>
            客宝宝隶属于深圳赛通信息科技有限公司，是融合新型互联网技术的服务管理系统开创者。客宝宝致力于通过新型互联网技术，打造符合数字化时代下企业需求的新一代服务管理系统，将企业同客户互动的全过程数字化、智能化，帮助企业提升客户满意度，实现可持续的业绩增长。
          </p>
          <p>
            公司旗下核心产品【客宝宝】，是国内领先的售后管理云平台。产品自推出以来广泛应用于机电设备、医疗器械、仪器仪表、家用电器、智能制造、安防监控、IT运维、家装建材、净水设备、手机数码等众多领域，在国内外拥有1000+家企业客户，每天有数百万人通过【客宝宝】平台获取服务。</p>
			<p>
            未来客宝宝将继续深耕企业级服务，持续专注于企业服务领域，在不断赋能企业优化业务流程和用户体验的同时，进一步利用新型互联网技术，为用户实现企业、客户、产品之间的无缝连接与交互，帮助企业构建真正的核心竞争力，成就卓越服务。</p>
        </div>

      </div>
    </div>
    <div class="culture">
      <div class="culture-title">
        企业文化
      </div>
      <div class="culture-content">
        <div class="culture-item">
          <div class="item-img">
            <img src="../../../public/about_cul1.png" alt="">
          </div>
          <div class="item-title">
            <img src="../../../public/about_culBg2.png" alt="">我们的愿景
          </div>
          <div class="item-content">
            成为⼀家拥抱变化、持续创新、客户信赖的好公司
          </div>
        </div>
        <div class="culture-item">
          <div class="item-img">
            <img src="../../../public/about_cul2.png" alt="">
          </div>
          <div class="item-title">
            <img src="../../../public/about_culBg2.png" alt="">我们的使命
          </div>
          <div class="item-content">
            通过互联网、物联网技术帮助企业进行服务升级
          </div>
        </div>
        <div class="culture-item">
          <div class="item-img">
            <img src="../../../public/about_cul3.png" alt="">
          </div>
          <div class="item-title">
            <img src="../../../public/about_culBg2.png" alt="">我们的价值观
          </div>
          <div class="item-content">
            追求卓越、持续创新、不断为客户创造新的价值
          </div>
        </div>
      </div>
    </div>
    <div class="honor">
      <div class="honor-title">
        荣誉资质
      </div>
      <div class="honor-content">
        <div class="honor-item">
          <img src="../../../public/honor1.png" alt="">
        </div>
        <div class="honor-item">
          <img src="../../../public/honor2.png" alt="">
        </div>
        <div class="honor-item">
          <img src="../../../public/honor3.png" alt="">
        </div>
        <div class="honor-item">
          <img src="../../../public/honor1.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  mounted() {
    console.log("download", Boolean(this._isMobile()));
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      this.$router.replace('/mobileAbout')
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      this.$router.replace('/about')
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 判断是什么设备
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  },
}
</script>
<style lang="scss" scoped>
.banner {
  height: 500px;
  background-image: url("../../../public/about_banner1.png");
  color: #fff;
  text-align: left;

  .banner-content {
    margin-left: 500px;

    h2 {
      font-size: 54px;
      color: #fff;
      line-height: 56px;
      padding-top: 180px;
    }

    p {
      font-size: 26px;
      color: #fff;
      line-height: 30px;
      margin-top: 21px;
      font-family: 'pingfang';
    }

  }
}

.brief {
  height: 687px;

  .brief-title {
    font-size: 32px;
    font-weight: bold;
    padding-top: 70px;
  }

  .brief-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    .brief-img {
      margin-right: 100px;
    }

    .brief-content {
      display: block;
      width: 600px;
      margin-top: 20px;
      text-indent: 2em;
      line-height: 35px;
      text-align: left;

    }
  }
}

.culture {
  height: 646px;
  background: url("../../../public/about_culBg.png");

  .culture-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 35px;
    padding-top: 100px;
    color: #ffffff;
  }

  .culture-content {
    display: flex;
    justify-content: center;
    padding-top: 100px;

    .culture-item {
      width: 300px;
      text-align: left;
      margin-right: 30px;

      .item-img {

      }

      .item-title {
        font-size: 18px;
        color: #ffffff;
        padding: 20px 0 10px;
        display: flex;
        align-items: center;
      }

      .item-content {
        font-size: 16px;
        color: #ffffff;
        line-height: 30px;
        border-top: 1px solid #dddddd;
        padding-top: 5px;
      }
    }
  }
}
.honor{
  padding-bottom: 50px;
  .honor-title{
    font-size: 32px;
    line-height: 35px;
    padding: 70px;
  }
  .honor-content{
    display: flex;
    justify-content: center;

    .honor-item{
      margin-right: 50px;
    }
  }
}
</style>
